<template>
  <div class="vf-ai-voice" :style='{height: clientH}'>
    <SearchNav :showSearch='false' :showTag='false'></SearchNav>
    <div class="vf-ai-voice-content">
      <div class='vf-ai-voice-list'>
        <div class="item" v-for="(item, index) in speakerList" :key="index">
          <div :class="['item-person',{ 'item-person-active': item.vcn === tts.speaker }]" @click="handleChoosePerson(item.vcn)">
            <img :src="item.img" />
            <span>{{ item.desc }}</span>
          </div>
          <div class="audition-btn active" @click="handleChoosePerson(item.vcn)">
            <AuditionBtn :item="item" :isPlaying="demo_url === item.demo_url" @handleSelectInfo="handleSelectInfo" />
          </div>
        </div>
      </div>


<!--      music_id: "73013a05d0b58097001"-->
<!--      object_id: "73013a05d0b58097001"-->
<!--      type: 1-->
<!--      voice_url: "https://qkodo.playlistmusic.com.cn/voice298196.mp3"-->

<!--      <div v-if="file && file !== '' " class="content-btn">-->
<!--        <div @click="playAiVoice">-->
<!--          <img :src="require(`@/assets/svg/${playing && musicId === globalMusicId ? 'pause' : 'play'}-icon-audio.svg`)" />-->
<!--        </div>-->
<!--        <div @click="addToCar">-->
<!--          <img :src="require('@/assets/svg/car-theme.svg')" />-->
<!--        </div>-->
<!--      </div>-->

    </div>
    <div class='voice-tab'>
      <span :class='tabidx===0?"active":null' @click='tabidx=0;'>语音内容</span>
      <span :class='tabidx===1?"active":null' @click='tabidx=1;'>声音调整</span>
    </div>
    <div class='voice-input'>
      <el-input
        v-if='tabidx===0'
        type="textarea"
        class="content-input"
        ref="input"
        :maxLength='800'
        v-model="tts.content"
        placeholder="请输入需要转换的文字……">
      </el-input>
      <div class='voice_sped' v-if='tabidx===1'>
        <div class="slider">
          <span>语速：</span>
          <el-slider
            class="slider-item"
            v-model="tts.speed"
            show-tooltip />
        </div>
        <div class="slider">
          <span>音量：</span>
          <el-slider
            class="slider-item"
            v-model="tts.volume"
            show-tooltip />
        </div>
        <div class="slider">
          <span>音高：</span>
          <el-slider
            class="slider-item"
            v-model="tts.pitch"
            show-tooltip />
        </div>
      </div>
    </div>
    <div class='voice_btn'>
      <span @click="toVoice">转换试听</span>
      <span @click="downloadVoice">下载语音</span>
    </div>
    <div class='autiobox' v-if='showAudio'>
      <audio ref="audio" :src="demo_url" @ended="onEnded"></audio>
      <audio :src="file" ref="aiPlayer"></audio>
    </div>
  </div>
</template>
<script>
import SearchNav from "./components/SearchNav.vue";
import { fetchVoicePerson } from "@/api/ai";
import Buttons from "@/layout/mixins/Buttons";
import { getToken } from "@/utils/author";
import axios from "axios";
import AuditionBtn from "../aiVoice/components/AuditionBtn";

const tips = ["请您输入要转换的文字内容", "您还没有进行转换"];

export default {
  components: { AuditionBtn,SearchNav },
  data() {
    return {
      showAudio: true,
      audioContent: null,
      clientH: '100%',
      tabidx: 0,
      speakerList: [], // 发音人列表
      token: null,
      tts: {
        content: "", // 文字
        speaker: "", // 选中发音人标识
        speed: 50, // 语速 0-100正整数
        volume: 50, // 音量 0-100正整数
        pitch: 50, // 音高 0-100正整数
        bgm: 0 // 是否添加背景音乐
      },
      file: "", // 生成的音频文件
      demo_url: "", // 试听音频
      musicId: "", // 音乐id
      isPlay: false // 是否在播放AI语音
    };
  },
  computed: {
    playing() {
      return this.$store.state.music.isPlaying;
    },
    globalMusicId() {
      return this.$store.state.music?.tmpMusicInfo?.object_id;
    }
  },
  watch: {
    "tts.content"() {
      this.file = null;
      this.musicId = null;
    },
    "tts.speaker"() {
      this.file = null;
      this.musicId = null;
    },
    "tts.speed"() {
      this.file = null;
      this.musicId = null;
    },
    "tts.volume"() {
      this.file = null;
      this.musicId = null;
    },
    "tts.pitch"() {
      this.file = null;
      this.musicId = null;
    },
    playing(val) {
      if (val) {
        this.onEnded();
      }
    },
    "tts.bgm"() {
      this.file = null;
      this.musicId = null;
    }
  },
  created() {
    this.showAudio = true;
    this.getPersonList();
    this.token = getToken();
    this.clientH = `${document.documentElement.clientHeight}px`;
    this.initAudio();
  },
  mixins: [Buttons],
  methods: {
    /**
     * 获取发音人列表
     */
    async getPersonList() {
      try {
        const { data } = await fetchVoicePerson();
        this.speakerList = data;
        this.tts.speaker = this.speakerList[0].vcn;
      } catch (e) {
        console.log(e);
      }
    },
    /**
     * 选择播放试听音频
     * 2022-04-18 16:48:54
     * @author SnowRock
     */
    handleSelectInfo(playing = true, demo_url) {
      if(this.audioContent?.src || this.file){
        // this.demoAudionPlaying = false;
        // this.$nextTick(() => {
        //   this.$refs.audio.currentTime = 0;
        //   this.$refs.audio.pause();
        // });
        // return;
        this.pauses();
        this.file = "";
        this.audioContent.src = "";
      }
      if (demo_url) {
        this.demo_url = demo_url === this.demo_url ? this.demo_url : demo_url;
      }
      this.demoAudionPlaying = playing;
      this.$nextTick(() => {
        this.$refs.audio.currentTime = 0;
        this.$refs.audio[playing ? "play" : "pause"]();
      });
    },
    /**
     * 试听播放结束
     * 2022-04-18 16:53:00
     * @author SnowRock
     */
    onEnded() {
      this.demoAudionPlaying = false;
      this.demo_url = "";
    },
    /**
     * 选择发音人
     * @param {String} vcn 发音人标识
     */
    handleChoosePerson(vcn) {
      this.tts.speaker = vcn;
    },

    initAudio() {
      if (!this.audioContent) {
        this.audioContent = new Audio();
      }
      this.audioContent.addEventListener("ended", this.endAi);
    },
    // 播放
    plays() {
      let audioContent = this.audioContent;
      try {
        typeof audioContent.play === "function" &&
        audioContent.play().catch(() => {
          this.pauses();
        });
      } catch (error) {
        console.log(error)
      }
    },
    // 暂停
    pauses() {
      const { audioContent } = this;
      audioContent && audioContent.pause && audioContent.pause();
    },
    // 播放结束
    endAi(){
      this.file = "";
      this.audioContent.src = "";
    },

    /**
     * 选择背景音乐
     */
    onChooseBgm() {
      this.tts.bgm === 0 ? (this.tts.bgm = 1) : (this.tts.bgm = 0); // 实现选中/取消选中
    },
    /**
     * 转换试听
     */
    async toVoice() {
      this.demoAudionPlaying = false;
      this.demo_url = "";
      this.$nextTick(() => {
        this.$refs.audio.currentTime = 0;
        this.$refs.audio.pause();
      });

      if (!this.tts.content) {
        this.$message.warning(tips[0]);
        return;
      }
      const params = this.tts;
      const token = getToken(); // 获取token值
      try {
        axios({
          url: `${process.env.VUE_APP_BASE_URL}/fontend/AIVoice/toVoice`,
          method: "post",
          headers: {
            "token": token
          },
          data: {
            content: params.content,
            vcn: params.speaker,
            speed: params.speed,
            volume: params.volume,
            pitch: params.pitch,
            bgs: params.bgm
          }
        }).then((res) => {
          if (res.data.code !== 0) {
            if (res.data.code == -1) {
              this.$message.error(res.data.msg);
              return;
            }
            if (res.data.code == 5002) {
              const token = getToken();
              // 如果未登录用户试听则进入登录页面
              if (!token) {
                this.$store.dispatch("user/openLogin");
                return;
              }
              if (!this.$store.state.user?.userInfo?.vip_info?.is_vip) {
                // 如果试听30次后无会员再次点击播放跳转至开通会员列表
                this.$router.push(`/package`);
                return;
              }
            }

            if (res.data.code == 2001 || res.data.code == 2002) {
              this.$store.dispatch("user/openLogin");
              return;
            }
            return;
          }
          this.file = res.data.data.voice_url;
          this.musicId = res.data.data.music_id;
          this.playAiVoice();
        });
      } catch (e) {
        console.log(e);
      }
    },
    playAiVoice(){
      this.demoAudionPlaying = false;
      this.demo_url = "";
      this.$nextTick(() => {
        this.$refs.audio.currentTime = 0;
        this.$refs.audio.pause();
      });

      this.audioContent.src = this.file;
      this.audioContent.load();
      this.plays();
    },
    /**
     * 播放AI语音
     */
    playAiVoiceOld() {
      if (!this.file) {
        this.$message.warning(tips[1]);
      } else {
        // 如果播放其他的语音的话 直接进行播放当前的语音
        if (this.musicId !== this.globalMusicId) {
          this.handlePlayerMusic({
            object_id: this.musicId,
            type: 1, // AI语音
            labels: [],
            watermark_file: this.file,
            duration: this.$refs.aiPlayer.duration
          });
          return;
        }
        // 如果当前音乐播放的是现有的语音条 则自动按播放暂停自动调整内容
        if (!this.playing) {
          this.handlePlayerMusic({
            object_id: this.musicId,
            type: 1, // AI语音
            labels: [],
            watermark_file: this.file,
            duration: this.$refs.aiPlayer.duration
          });
          return
        }
        // 默认暂停列表
        this.$store.dispatch("music/pause");
      }
    },

    /**
     * 下载语音
     */
    downloadVoice() {
      if (!this.file || !this.musicId) {
        this.$message.warning(tips[1]);
      } else {
        this.handleDownload({ object_id: this.musicId, type: 1 }, "single");
      }
    },

    /**
     * 添加至购物车
     */
    addToCar() {
      if (!this.file || !this.musicId) {
        this.$message.warning(tips[1]);
      } else {
        this.handleAddGoodsToCart({
          object_id: this.musicId,
          type: 1
        });
      }
    }
  },
  beforeDestroy() {
    this.showAudio = false;
    if(this.audioContent){
      this.pauses();
      this.file = "";
      this.audioContent.src = "";
    }
    this.demoAudionPlaying = false;
    this.demo_url = "";
    if(this.$refs.audio){
      this.$refs.audio.src = "";
      this.$refs.audio.currentTime = 0;
      this.$refs.audio.pause();
    }
    this.$store.dispatch("music/pause");
  },
};
</script>
<style lang="scss" scoped>
.vf-ai-voice {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  &-content {
    width: 100%;
    margin-top: 16px;
    padding: 0 20px;
    box-sizing: border-box;

    //&-operation {
    //  display: flex;
    //  justify-content: space-between;
    //
    //  &-left {
    //    margin-top: 19px;
    //    margin-right: 45px;
    //    width: calc(50% - 45px);
    //
    //    .content {
    //      position: relative;
    //
    //      .input[contenteditable]:empty:not(:focus):before {
    //        content: attr(placeholder);
    //        color: rgba($font-theme-color, 0.36);
    //      }
    //

    //
    //    .download-count {
    //      margin-top: 20px;
    //      @include font-normal($size: 20px, $color: rgba(51, 51, 51, 0.48));
    //      line-height: 28px;
    //      font-size: 18px;
    //
    //      i {
    //        color: #ff9828;
    //        margin-right: 12px;
    //      }
    //    }
    //  }
    //
    //  &-right {
    //    flex: auto;
    //    margin-top: 19px;
    //
    //    .title {
    //      @include font-bold($size: 20px, $color: rgba(51, 51, 51, 0.8));
    //      line-height: 28px;
    //      margin-right: 13px;
    //    }
    //
    //    .choose-voice {
    //      display: flex;
    //
    //      &-content {
    //        display: grid;
    //        grid-template-columns: repeat(5, 1fr);
    //        grid-gap: 12px 8px;
    //        grid-auto-flow: row;
    //        margin-top: -3px;
    //
    //        .item {
    //          display: inline-block;
    //          align-items: center;
    //          vertical-align: top;
    //          text-align: center;
    //
    //          &-person {
    //            @include center-middle-flex;
    //            flex-direction: column;
    //            @include square(70px);
    //            border-radius: 8px;
    //            background: rgba(178, 178, 178, 0.1);
    //            cursor: pointer;
    //
    //            img {
    //              @include square(40px);
    //            }
    //
    //            span {
    //              @include font-normal(
    //                $size: 14px,
    //                $color: rgba(51, 51, 51, 0.5)
    //              );
    //              line-height: 20px;
    //              margin-top: 2px;
    //            }
    //          }
    //
    //          &-person-active {
    //            background: rgba($theme-color, 0.1);
    //          }
    //
    //          &-point {
    //            @include square(6px);
    //            border-radius: 50%;
    //            margin-top: 8px;
    //          }
    //
    //          &-point-active {
    //            background: $theme-color;
    //          }
    //        }
    //      }
    //    }
    //
    //    .choose-params {
    //      display: flex;
    //      margin-top: 30px;
    //
    //      &-content {
    //        margin-top: -3px;
    //
    //        .slider:nth-last-of-type(1) {
    //          margin-bottom: 0;
    //        }
    //
    //        .slider {
    //          @include center-middle-flex;
    //          margin-bottom: 10px;
    //
    //          span {
    //            @include font-normal(
    //              $size: 16px,
    //              $color: rgba(51, 51, 51, 0.56)
    //            );
    //            margin-right: 12px;
    //            line-height: 22px;
    //          }
    //
    //          &-item {
    //            width: 316px;
    //          }
    //

    //        }
    //      }
    //    }
    //
    //    .choose-bgm {
    //      @include center-middle-flex($justify: flex-start);
    //      margin-top: 24px;
    //
    //      /deep/ {
    //        .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled)
    //        .el-radio__inner {
    //          box-shadow: none; // 去掉选中时的光圈
    //        }
    //
    //        .el-radio {
    //          .el-radio__label {
    //            color: transparent;
    //          }
    //
    //          .el-radio__inner {
    //            @include square(24px);
    //            border: 2px solid rgba(51, 51, 51, 0.56);
    //            outline: none;
    //          }
    //
    //          &.is-checked {
    //            .el-radio__inner {
    //              background-color: #fff;
    //              border-color: rgba(51, 51, 51, 0.56);
    //            }
    //          }
    //
    //          .el-radio__inner::after {
    //            @include square(12px);
    //            background: rgba(51, 51, 51, 0.56);
    //          }
    //
    //          .el-radio__inner {
    //            &:hover {
    //              border-color: rgba(51, 51, 51, 0.56);
    //            }
    //          }
    //        }
    //      }
    //    }
    //
    //    .btn {
    //      @include center-middle-flex($justify: flex-end);
    //      margin-top: 56px;
    //
    //      span {
    //        @include size(138px, 40px);
    //        @include center-middle-flex;
    //        border-radius: 2px;
    //        border: 1px solid #E52A0D;
    //        @include font-bold($size: 20px, $color: $theme-color);
    //        cursor: pointer;
    //      }
    //
    //      span:nth-last-of-type(1) {
    //        background: $theme-color;
    //        color: #fff;
    //        margin-left: 20px;
    //      }
    //    }
    //

    //  }
    //}
  }
  &-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 12px 8px;
    grid-auto-flow: row;
    .item {
      width: 100%;
      overflow: hidden;
      &-person {
        width: 100%;
        padding: 4px 7px 8px 7px;
        box-sizing: border-box;
        background: rgba(51,51,51,0.05);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        img {
          display: inline-block;
          width: 100%;
        }
        span {
          text-align: center;
          display: inline-block;
          @include font-normal(
            $size: 0.2rem,
            $color: rgba(51, 51, 51, 0.5)
          );
          margin-top: 5px;
          line-height: 12px;
        }
      }

      &-person-active {
        background: rgba($theme-color, 0.1);
      }

      .audition-btn {
        width: 100%;
        margin-top: 8px;
        line-height: 22px;
        height: 24px;
        color: #E52A0D;
        font-size: 14px;
        border: 1px solid #E52A0D;
        border-radius: 5px;
        box-sizing: border-box;
        opacity: 0;
        display: none;
        cursor: pointer;
        &.active {
          opacity: 1;
          display: inline-block;
        }
      }

      &-point {
        @include square(6px);
        border-radius: 50%;
        margin-top: 8px;
      }

      &-point-active {
        background: $theme-color;
      }
    }
  }
  .voice-tab{
    width: 100%;
    height: 40px;
    box-shadow: 0px 2px 7px 0px rgba(0,0,0,0.05);
    margin-top: 30px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    &:before{
      content: "";
      width: 100%;
      height: 10px;
      background: #F8F8F9;
      position: absolute;
      top: -10px;
    }
    span{
      display: inline-block;
      height: 100%;
      line-height: 40px;
      position: relative;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(51,51,51,0.5);
    }
    span.active{
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      &:after{
        content:"";
        width: 26px;
        height: 3px;
        background: #E52A0D;
        border-radius: 2px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  .voice-input{
    flex: 1;
    margin-top: 23px;
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
  }
  .voice_sped{
    width: 100%;
    .slider{
      display: flex;
      align-items: center;
      span{
        display: inline-block;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(51,51,51,0.56);
        margin-right: 20px;
      }
      .el-slider{
        flex: 1;
      }
    }
  }
  .voice_btn{
    //position: fixed;
    //bottom: 20px;
    width: 100%;
    //height: 44px;
    padding: 0 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    span{
      flex: 1;
      text-align: center;
      line-height: 44px;
      border-radius: 4px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      &:first-child{
        margin-right: 5px;
        border: 1px solid #E52A0D;
        color: #E52A0D;
      }
      &:last-child{
        margin-left: 5px;
        background: linear-gradient(135deg, rgba(255,47,15,0.6) 0%, #E52A0D 100%);
        color: #fff;
      }
    }
  }
  .autiobox{
    visibility: hidden;
    height: 0;
    overflow: hidden;
  }
  /deep/ {
    .content-input {
      @include size(100%, 90%);
      overflow-y: scroll;
      border-radius: 6px;
      opacity: 0.6;
      @include font-bold($size: 16px, $color: #000);
      line-height: 28px;
      outline: none;
      padding-right: 0;

      textarea {
        width: 100%;
        height: 100%;
        margin: 0;
        border: none;
        outline: none;
        background: rgba(178, 178, 178, 0.16);
        border-radius: 12px;
        padding: 16px 20px;
        color: #333;
      }
    }

    .el-slider__runway {
      height: 8px;
      border-radius: 4px;
      background: #d8d8d8;

      .el-slider__bar {
        height: 100%;
        border-radius: 4px;
      }

      .el-slider__button-wrapper {
        width: 36px;
        height: 36px;
        z-index: 1;
      }

      .el-slider__button {
        @include size(8px, 18px);
        background: $theme-color;
        border: none;
        border-radius: 5px;
      }
    }
  }

  &-btn {
    position: absolute;
    bottom: 9px;
    right: 14px;
    @include center-middle-flex;
    cursor: pointer;

    div {
      @include square(56px);
      @include center-middle-flex;
      background: $base-color;
      box-shadow: 0px 0px 12px 0px rgba(33, 48, 83, 0.07);
      border-radius: 50%;

      img {
        @include square(28px);
      }
    }

    div:nth-of-type(1) {
      margin-right: 12px;
    }
  }
}
</style>
